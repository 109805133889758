<template>
  <div v-if="checkPermission(['company', 'PUT'])">
    <span class="text-center table-edit-icon" @click="showModal">
      <i class="text-center material-icons">edit</i>
    </span>
    <div class="centerx">
      <vs-popup
        :id="'popmodal' + params.data.company_id"
        class="holamundo editModal"
        title="Update Company"
        :active.sync="popupActive"
      >
        <form ref="testingRef">
          <FocusLock>
            <div class="vx-row">
              <!-- LEFT SIDE -->
              <div class="col-12">
                <div class="vx-row mb-6">
                  <div class="col-md-4 text-right align-self-center">
                    <h5 class="mb-0">Logo</h5>
                  </div>
                  <div class="col-md-7">
                    <div class="text-center">
                      <label
                        for="updateProfilePicture"
                        class="position-relative mb-0"
                      >
                        <img
                          v-if="form.company_logo && form.company_logo != '-'"
                          :src="form.company_logo + '?token=' + tempToken"
                          class="profile-img"
                        />
                        <img
                          v-else
                          src="https://img.gokozo.com/c5d9223c6127d9bc_org.png"
                          class="profile-img"
                        />
                        <div class="comp-logo-update mt-2">
                          <i class="text-center material-icons align-middle"
                            >arrow_upward</i
                          >
                          update
                        </div>
                      </label>
                      <input
                        type="file"
                        @change="uploadFile($event)"
                        class="d-none"
                        id="updateProfilePicture"
                      />
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="col-md-4 text-right align-self-center">
                    <h5 class="mb-0">Name</h5>
                  </div>
                  <div class="col-md-7">
                    <div class>
                      <vs-input
                        disabled
                        v-validate="'required'"
                        name="Company Name"
                        v-model="form.company_name"
                        class="w-full"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Company Name')"
                        >{{ errors.first("Company Name") }}</span
                      >
                    </div>
                  </div>
                </div>

                <!-- ********************** -->
                <div class="vx-row mb-6">
                  <div class="col-md-4 text-right align-self-center">
                    <h5 class="mb-0">Email*</h5>
                  </div>
                  <div class="col-md-7">
                    <div class>
                      <vs-input
                        v-validate="'required|email'"
                        name="Company Email"
                        v-model="form.company_email"
                        class="w-full"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Company name')"
                        >{{ errors.first("Company name") }}</span
                      >
                    </div>
                  </div>
                </div>

                <!-- <div class="vx-row mb-6">
                <div class="col-md-4 text-right align-self-center">
                  <h5 class="mb-0">Phone *</h5>
                </div>
                <div class="col-md-7">
                  <div class>
                    <vs-input
                      v-validate="'required'"
                      name="Company Phone"
                      v-model="form.company_phone"
                      class="w-full"
                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('Company Phone')"
                    >{{ errors.first('Company Phone') }}</span>
                  </div>
                </div>
                </div>-->

                <div class="vx-row mb-6">
                  <div class="col-md-4 text-right align-self-center">
                    <h5 class="mb-0">Address*</h5>
                  </div>
                  <div class="col-md-7">
                    <div class>
                      <vs-textarea
                        v-model="form.company_address"
                        class="mb-0"
                        rows="4"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Company Address')"
                        >{{ errors.first("Company Address") }}</span
                      >
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="col-md-4 text-right align-self-center">
                    <h5 class="mb-0">Phone*</h5>
                  </div>
                  <div class="col-md-7">
                    <div class>
                      <vs-input
                        v-validate="'required|numeric|max:14'"
                        name="Company Phone"
                        v-model="form.company_phone"
                        class="w-full"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Company Phone')"
                        >{{ errors.first("Company Phone") }}</span
                      >
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="col-md-4 text-right align-self-center">
                    <h5 class="mb-0">Website*</h5>
                  </div>
                  <div class="col-md-7">
                    <div class>
                      <vs-input
                        v-validate="'required'"
                        name="Company Website"
                        v-model="form.company_url"
                        class="w-full"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Company Website')"
                        >{{ errors.first("Company Website") }}</span
                      >
                    </div>
                  </div>
                </div>

                <!-- ********************** -->

                <div class="vx-row mb-6">
                  <div class="col-md-4 text-right">
                    <h5 class="mb-0">Description</h5>
                  </div>
                  <div class="col-md-7">
                    <vs-textarea
                      v-model="form.company_description"
                      class="mb-0"
                      rows="4"
                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('Company Description')"
                      >{{ errors.first("Company Description") }}</span
                    >
                  </div>
                </div>
                <!-- <div class="v">
                <div class="col-md-4 text-right">
                  <h5 class="mb-0">Status*x-row mb-6</h5>
                </div>
                <div class="col-md-7">
                  <vs-switch false-value="2" true-value="1" v-model="form.company_active" />
                </div>
                </div>-->
              </div>
            </div>

            <!-- SUBMIT BUTTON -->
            <div class="vx-row">
              <div class="vx-col mx-auto">
                <!-- :disabled="submitStatus" -->
                <vs-button
                  :disabled="submitStatus"
                  type="filled"
                  @click.prevent="submitForm"
                  class="mr-3"
                  >Update</vs-button
                >

                <!-- <vs-button color="warning" type="border" class @click.prevent="clearForm">Reset</vs-button> -->
              </div>
            </div>
          </FocusLock>
        </form>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import { BASEURL } from "@/config/index";
import departmentService from "@/services/departmentService.js";
import CompanyService from "@/services/companyService.js";
import { error } from "util";
export default Vue.extend({
  data() {
    return {
      tempToken: window.localStorage.getItem("TempToken"),
      submitStatus: true,
      form: {
        company_name: "",
        company_email: "",
        company_phone: "",
        country_id: null,
        company_address: "",
        company_active: 1,
        company_url: "",
        company_logo: "",
        company_description: "",
      },
      companies: [],
      popupActive: false,
      params: null,
      value: null,
      count: 0,
    };
  },
  watch: {
    form: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          // if (this.count > 0) {
          this.submitStatus = false;
        }
        this.count++;
      },
      deep: true,
    },
  },
  beforeMount() {
    this.value = this.params.value;
    this.form = this.params.data;
  },
  beforeDestroy() {
    let _id = document.getElementById("popmodal" + this.params.data.company_id);
    _id.setAttribute("style", "display: none;");
  },
  methods: {
    showModal() {
      // console.log(this.params.data.department_active, "fsdfsd");
      //   if (
      //     this.params.data.department_active == 2 ||
      //     this.params.data.department_active == "2"
      //   ) {
      //     this.switch1 = false;
      //   } else {
      //     this.switch1 = true;
      //   }
      // console.log("afsadf");
      this.popupActive = true;
    },
    uploadFile(event) {
      let formData = new FormData();
      let organization_id = window.localStorage.getItem("OrganizationID");
      formData.append("file[]", event.target.files[0]);
      axios
        .post(
          BASEURL() + "/v2/multipleUploads?organization_id=" + organization_id,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              authorization: this.access_token,
            },
          }
        )
        .then(function (res) {
          // console.log("res :-", res);
          console.log("form :- ", this.form);
          this.form.company_logo = res.data.data.image_path;
          console.log("this.form.company_logo :", this.form.company_logo);
        })
        .catch(function (err) {
          // this.$vs.notify({
          //   title: "Error!",
          //   text: err.message,
          //   iconPack: "feather",
          //   icon: "check_box",
          //   color: "warning",
          // });
        });
    },
    submitForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();
          let id = this.params.data.company_id;
          let payload = {
            company_name: this.form.company_name,
            company_email: this.form.company_email,
            company_phone: this.form.company_phone,
            country_id: this.form.country_id,
            company_address: this.form.company_address,
            company_active: this.form.company_active,
            company_url: this.form.company_url,
            company_logo: this.form.company_logo,
            company_description: this.form.company_description,
          };
          CompanyService.editCompany(payload, id)
            .then((response) => {
              this.$vs.loading.close();
              this.submitStatus = false;
              const { data } = response;
              this.popupActive = false;
              if (data.Status == true || data.Status == "true") {
                this.$vs.notify({
                  title: "Updated!",
                  text: data.Message,

                  iconPack: "feather",
                  icon: "check_box",
                  color: "success",
                });
                setTimeout(() => {
                  eventBus.$emit("refreshTablecomp", data);
                }, 1);
                this.clearForm();
              } else {
                this.$vs.loading.close();
                console.log("data :", data);
                this.$vs.notify({
                  title: "Error!",
                  text: data.Message,

                  iconPack: "feather",
                  icon: "check_box",
                  color: "warning",
                });
              }
            })
            .catch((error) => {
              this.$vs.loading.close();
              this.submitStatus = true;
              console.log("error :", error);
            });
          // if form have no errors
          // alert("form submitted!");
        } else {
          // form have errors
        }
      });
    },
    getCompanyList: function () {
      // console.log("RolesService :", RolesService);
      CompanyService.editCompany()
        .then((response) => {
          const { data } = response;
          if (data.err || data.Status == false) {
          } else {
            this.companies = data.data;
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },
    clearForm: function () {
      Object.keys(this.form).map((x, index) => {
        /_active/.test(x) ? (this.form[x] = "1") : (this.form[x] = "");
      });
      this.$validator.reset();
    },
  },

  // mounted() {
  //   this.getCompanyList();
  // },
});
</script>
<style></style>
